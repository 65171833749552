<template>
  <v-row no-gutters>
    <div id="tracking-list">
      <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />

      <v-card
        tile
        :flat="$vuetify.breakpoint.mdAndDown"
        :class="[{ 'rounded-t-lg': !$vuetify.breakpoint.mdAndDown }, { 'rounded-l-xl': userData.role === 'SUPER' }]"
      >
        <v-toolbar :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
          <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
            <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
              {{ icons.mdiCartCheck }}
            </v-icon>

            <span v-if="userData.role === 'SUBCUST'"> Sipariş Takip</span>

            <span v-else> Tali Bayi Onay Bekleyen Siparişler</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="taliListTable"
          fixed-header
          :loading="loading"
          item-key="id"
          show-expand
          single-expand
          :footer-props="{
            showFirstLastPage: true,
          }"
        >
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                :headers="childHeaders"
                :items="item.products"
                :items-per-page="-1"
                hide-default-footer
                dense
              >
              </v-data-table>
            </td>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-btn
              v-if="userData.role !== 'SUBCUST'"
              small
              class="ml-2"
              color="success"
              @click=";(isFinal = 0), saveTable(item)"
              >Sepete Aktar
            </v-btn>
          </template>

          <template #[`item.statetxt`]="{ item }">
            <v-chip :color="item.statecolor" link outlined small>
              {{ item.statetxt }}
            </v-chip>
          </template>

          <template #[`item.address.address`]="{ item }">
            {{ item.address.name }} - {{ item.address.address }}
          </template>
        </v-data-table>
      </v-card>
    </div>
  </v-row>
</template>

<script>
import { mdiCartCheck } from '@mdi/js'

import CustomerList from '@/components/CustomerList.vue'

import store from '@/store'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    CustomerList,
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))

    onMounted(() => {
      const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'

      // Register module
      if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
        store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
      }

      fetchTaliBayi()
    })

    const taliListTable = ref([])

    const childHeaders = [
      {
        text: 'Malzeme Kodu',
        value: 'material',
      },
      {
        text: 'Malzeme Adı',
        value: 'stext',
      },

      {
        text: 'Miktar',
        value: 'qty',
      },
    ]
    const tableColumns = [
      {
        text: 'Tarih',
        value: 'create_datetime',
      },
      {
        text: 'Durum',
        value: 'statetxt',
      },
      {
        text: 'Tali Bayi',
        value: 'adsoyad',
      },

      {
        text: 'Sevk Adresi',
        value: 'address.address',
      },
      {
        text: '',
        value: 'actions',
      },
    ]

    const loading = ref(false)

    const fetchTaliBayi = () => {
      store
        .dispatch('postMethod', {
          method: 'subcustOrderListWaiting',
        })
        .then(response => {
          if (response.error === 0) {
            console.log(response)
            taliListTable.value = response.response.result
            loading.value = false
          } else {
            Vue.swal({
              title: 'Hata',
              html: response.msg,
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            loading.value = false
          }
        })
        .catch(error => {
          if (error.response === 404) {
            userData.value = {}
            loading.value = false
          }
        })
    }

    const isFinal = ref(0)

    const saveTable = item => {
      console.log(item)
      if (store.state['app-order'].basketItemCount != 0) {
        Vue.swal({
          title: 'Emin misiniz?',
          text: isFinal.value == 0 ? 'Seçimleri Onaylıyor musunuz?' : 'Sipariş Belgesini Kaydetmek İstiyor musunuz?',
          icon: 'question',
          showCancelButton: true,
          // background: isFinal == 0 ? '' : '#FFB400',
          confirmButtonText: 'Onaylıyorum',
        }).then(result => {
          if (result.isConfirmed) {
            const param = {
              method: 'actualizeSubcustBasket',
              uid: item.uid,
            }

            store
              .dispatch('postMethod', param)
              .then(response => {
                if (response.error === 0) {
                  Vue.swal({
                    title: 'Seçimleriniz Kaydedildi',
                    text: 'İşlem başarılı',
                    icon: 'success',
                    timer: 1700,
                    background: '#56CA00',
                    timerProgressBar: true,
                    showConfirmButton: false,
                  })

                  loading.value = true

                  console.log('basarılı')
                  store.commit('app/TOGGLE_BASKET_DRAWER', true)
                } else {
                  //dialog.value = false
                  Vue.swal({
                    title: 'Hata',
                    html: response.msg,
                    icon: 'error',
                    background: '#FF4C51',
                    timerProgressBar: true,
                    showConfirmButton: false,
                  })
                }
              })
              .catch(error => {
                dialog.value = false
                console.log(error)
              })
          }
        })
      } else {
        const param = {
          method: 'actualizeSubcustBasket',
          uid: item.uid,
        }

        store.dispatch('postMethod', param).then(response => {
          if (response.error === 0) {
            //fetchTaliBayi()

            console.log('basarılı')
            store.commit('app/TOGGLE_BASKET_DRAWER', true)
            loading.value = false
          } else {
            //dialog.value = false
            Vue.swal({
              title: 'Hata',
              html: response.msg,
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })
      }
    }

    return {
      childHeaders,
      userData,
      fetchTaliBayi,
      tableColumns,
      saveTable,
      loading,
      taliListTable,
      isFinal,

      // icons
      icons: {
        mdiCartCheck,
      },
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
:root {
  --height: 30px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  //height: var(--height);
}
.v-data-footer__select {
  display: none !important;
}

.v-data-table__expanded__content > td {
  //background: #28243d !important;
  padding: 0 0 20px 0 !important;
}
</style>
