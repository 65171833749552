var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('div',{attrs:{"id":"tracking-list"}},[(_vm.userData.role === 'PLSYR' && _vm.$vuetify.breakpoint.xsOnly)?_c('customer-list'):_vm._e(),_c('v-card',{class:[{ 'rounded-t-lg': !_vm.$vuetify.breakpoint.mdAndDown }, { 'rounded-l-xl': _vm.userData.role === 'SUPER' }],attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-toolbar',{attrs:{"flat":!_vm.$vuetify.breakpoint.smAndDown,"height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-body-2 text-md-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":!_vm.$vuetify.breakpoint.smAndDown,"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCartCheck)+" ")]),(_vm.userData.role === 'SUBCUST')?_c('span',[_vm._v(" Sipariş Takip")]):_c('span',[_vm._v(" Tali Bayi Onay Bekleyen Siparişler")])],1),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.taliListTable,"fixed-header":"","loading":_vm.loading,"item-key":"id","show-expand":"","single-expand":"","footer-props":{
          showFirstLastPage: true,
        }},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.childHeaders,"items":item.products,"items-per-page":-1,"hide-default-footer":"","dense":""}})],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.userData.role !== 'SUBCUST')?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"success"},on:{"click":function($event){;(_vm.isFinal = 0), _vm.saveTable(item)}}},[_vm._v("Sepete Aktar ")]):_vm._e()]}},{key:"item.statetxt",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.statecolor,"link":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.statetxt)+" ")])]}},{key:"item.address.address",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.address.name)+" - "+_vm._s(item.address.address)+" ")]}}],null,true)})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }